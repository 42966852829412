import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@storyblok/react/dist/bridge-loader.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@storyblok/react/dist/story.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@virginmediao2/momentum-nextjs/dist/assets/block.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@virginmediao2/momentum-nextjs/dist/assets/flex.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@virginmediao2/momentum-nextjs/dist/assets/box.css");
;
import(/* webpackMode: "eager", webpackExports: ["ShowMore"] */ "/app/node_modules/@virginmediao2/momentum-nextjs/dist/show-more/show-more.js");
;
import(/* webpackMode: "eager", webpackExports: ["Child","Tab"] */ "/app/node_modules/@virginmediao2/momentum-nextjs/dist/tabs/tab.js");
;
import(/* webpackMode: "eager", webpackExports: ["Tabs"] */ "/app/node_modules/@virginmediao2/momentum-nextjs/dist/tabs/tabs.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/_components/error-boundary.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GoogleTagManagerEvents"] */ "/app/src/app/_components/google-tag-manager-events.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/mini-apps/help-search-input/help-search-input.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/mini-apps/iframe-embed/iframe-embed.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DeviceNameForm"] */ "/app/src/components/mini-apps/imei-checker/device-form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DeviceModelNumberForm"] */ "/app/src/components/mini-apps/imei-checker/group-form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ImeiNumberForm"] */ "/app/src/components/mini-apps/imei-checker/imei-form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["InternationalCharges"] */ "/app/src/components/mini-apps/intl-charges/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/mini-apps/mobile-number-form/mobile-number-form.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/mini-apps/overpayment-calculator/overpayment-calculator.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/mini-apps/rpi/rpi.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/mini-apps/speed-perform-embed/speed-perform-embed.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/mini-apps/support-request-form/support-request-form.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/mini-apps/unlock/form.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/momentum/components/accordion/accordion.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ButtonLink"] */ "/app/src/components/momentum/components/button/button-link.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Button"] */ "/app/src/components/momentum/components/button/button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Carousel"] */ "/app/src/components/momentum/components/carousel/carousel.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/momentum/components/content/content.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/momentum/components/filter-chips/filter-chips.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Grid"] */ "/app/src/components/momentum/components/grid/grid.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/momentum/components/horizontal-rule/horizontal-rule.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/momentum/components/media-banner/media-banner.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/momentum/components/modal/modal.context.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/momentum/components/modal/modal.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/momentum/components/offer-card/offer-card.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/momentum/components/rail/rail.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/momentum/components/scrollable/scrollable.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/momentum/components/voucher/voucher.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/momentum/factory/mega-menu/mega-menu-functions.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SideNavigationItem"] */ "/app/src/components/momentum/factory/mega-menu/side-navigation/side-navigation-item/side-navigation-item.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/momentum/factory/mega-menu/side-navigation/side-navigation-trigger/side-navigation-trigger.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/momentum/theme/vmo2-theme/index.ts");
;
import(/* webpackMode: "eager" */ "/app/src/components/storyblok/bloks/doodad_get_puk_code/doodad_get_puk_code.module.css");
;
import(/* webpackMode: "eager" */ "/app/src/components/storyblok/bloks/doodad_international_charges/doodad_international_charges.module.css");
;
import(/* webpackMode: "eager" */ "/app/src/components/storyblok/bloks/dynamo-accordion/dynamo-accordion.module.css");
;
import(/* webpackMode: "eager" */ "/app/src/components/momentum/components/icon/icon.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/momentum/components/text/text.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/momentum/components/alert/alert.module.scss");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@virginmediao2/momentum-nextjs/dist/assets/icon.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@virginmediao2/momentum-nextjs/dist/assets/text.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@virginmediao2/momentum-nextjs/dist/assets/badge.css");
;
import(/* webpackMode: "eager" */ "/app/src/components/momentum/components/badge/badge.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/momentum/components/anchor/anchor.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/momentum/components/breadcrumbs/breadcrumbs.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/momentum/components/card/card.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/momentum/components/description-list/description-list.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/momentum/theme/o2-rebrand-theme/o2-rebrand-theme.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/momentum/theme/o2-theme/o2-theme.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/momentum/theme/vm-theme/vm-theme.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/storyblok/bloks/dynamo-dynamic-page/dynamo-dynamic-page.module.css");
;
import(/* webpackMode: "eager" */ "/app/src/components/momentum/components/feature/feature.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/momentum/components/image-link/image-link.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/storyblok/bloks/dynamo-layout/dynamo-layout.module.css");
;
import(/* webpackMode: "eager" */ "/app/src/components/momentum/components/list/list.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/storyblok/bloks/dynamo-livechat/dynamo-livechat.module.css");
;
import(/* webpackMode: "eager" */ "/app/src/components/momentum/components/section/section.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/momentum/components/masthead/masthead.module.css");
;
import(/* webpackMode: "eager" */ "/app/src/components/storyblok/components/responsive-image/responsive-image.module.css");
;
import(/* webpackMode: "eager" */ "/app/src/components/momentum/components/media-image/media-image.module.css");
;
import(/* webpackMode: "eager" */ "/app/src/components/momentum/factory/mega-menu/megamenu-global.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/momentum/factory/mega-menu/megamenu.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/momentum/factory/mega-menu/prefix/megamenu-prefix.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/momentum/factory/mega-menu/utils/utils.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/momentum/factory/mega-menu/navigation/navigation.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/momentum/factory/mega-menu/navigation/l1/l1-navigation-item.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/momentum/factory/mega-menu/navigation/l2/l2-navigation.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/storyblok/bloks/dynamo-megamenu/dropdown/l3-category.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/momentum/factory/mega-menu/side-navigation/side-navigation.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/momentum/factory/topbar/topbar.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/momentum/components/o2-footer/o2-footer.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/momentum/components/o2-footer/o2-footer-navlist.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/storyblok/bloks/dynamo-offer-card/dynamo-offer-card.module.css");
;
import(/* webpackMode: "eager" */ "/app/src/components/storyblok/bloks/dynamo-rail/dynamo-rail.module.css");
;
import(/* webpackMode: "eager" */ "/app/src/components/momentum/components/shout-out-banner/shout-out-banner.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/momentum/components/step-indicator/step-indicator.module.scss");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@virginmediao2/momentum-nextjs/dist/assets/alert.css");
;
import(/* webpackMode: "eager", webpackExports: ["ClientTabs"] */ "/app/src/components/storyblok/bloks/dynamo-tabs/ClientTabs.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/storyblok/bloks/dynamo-tabs/dynamo-tabs.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/momentum/components/tile/tile.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/momentum/components/vertical-rhythm/vertical-rhythm.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/momentum/components/video/video.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/momentum/components/youtube-player/youtube-player.module.css");
;
import(/* webpackMode: "eager" */ "/app/src/components/momentum/components/card-simple/card-simple.module.css");
;
import(/* webpackMode: "eager" */ "/app/src/components/momentum/components/peek/peek.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/storyblok/bloks/o2-sc-sidenav/o2-sc-sidenav.module.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@virginmediao2/momentum-nextjs/dist/assets/card.css");
;
import(/* webpackMode: "eager", webpackExports: ["Feedback"] */ "/app/src/components/storyblok/bloks/o2-sc-feedback/feedback.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/storyblok/bloks/o2-sc-static-product-card/product-card.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/storyblok/renderer/storyblok-bridge.tsx");
