'use client';

import { usePathname, useSearchParams } from 'next/navigation';
import { FC, useEffect } from 'react';

const platform = 'storyblok';

export interface GoogleTagManagerEventsProps {
  env: string;
  pageId: number | string;
}

export const GoogleTagManagerEvents: FC<GoogleTagManagerEventsProps> = ({
  env,
  pageId,
}) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const environment = env === 'stage' ? 'staging' : 'production';

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    const page_id = pageId.toString();
    const page_url = window.location.href;
    const path = window.location.pathname;
    const page_name = ['portal:con', ...path.split('/')]
      .filter(Boolean)
      .join('|');

    const page_referrer = document.referrer;
    const query = window.location.search;

    const event = {
      event: 'pageview',
      event_name: 'page_view',
      page: {
        environment,
        platform,
        page_id,
        page_referrer,
        page_name,
        page_url,
        query,
      },
    };

    window.dataLayer.push(event);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, searchParams]);

  return null;
};
