'use client';

import { ParsedUrlQuery, ParsedUrlQueryInput } from 'node:querystring';

import { storyblokInit, useStoryblokBridge } from '@storyblok/react/rsc';
import { FC, JSX, ReactNode, useCallback, useEffect, useState } from 'react';

import { renderPage } from '@/components/storyblok/renderer/action';
import { Story } from '@/types/story.interface';

storyblokInit({
  accessToken: '',
});

export interface StoryblokBridgeProps {
  children: ReactNode;
  storyId: number;
  searchParams: ParsedUrlQueryInput;
  version: 'draft' | 'published';
  releaseId?: string;
  contentVersion?: string;
  params: ParsedUrlQuery;
  resolveRelations: Array<string>;
}

export const StoryblokBridge: FC<StoryblokBridgeProps> = ({
  storyId,
  searchParams,
  version,
  releaseId,
  params,
  resolveRelations,
  contentVersion,
  children,
}) => {
  const [preview, setPreview] = useState<JSX.Element>();

  useEffect(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useStoryblokBridge(
      storyId,
      async (s) => {
        const story = s as unknown as Story<unknown>;
        const page = await renderPage({
          params,
          searchParams,
          story,
          version,
          releaseId,
          contentVersion,
        });
        setPreview(page);
      },
      {
        resolveLinks: 'url',
        resolveRelations,
      }
    );
  }, [
    resolveRelations,
    storyId,
    params,
    searchParams,
    version,
    contentVersion,
    releaseId,
  ]);

  return preview || children;
};

export default StoryblokBridge;
